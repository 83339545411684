export enum QUEST_TYPE {
  INVITE = 'invite',
  INVITE_GAIN = 'inviteAndGainPoints',
  KYC = 'kyc',
  SOUL_BOUND_NFT = 'soulBoundNft',
  COLLECT_CHAMNP = 'collectChamp',
  VALIDATE = 'validate',
  SPECIAL_EVENTS = 'SpecialEvents',
  CONNECT_DISCORD = 'discordVerifyRole',
  YOUTUBE_POST_VIDEO = 'youtubePostVideo',
  DISCORD_VERIFY_MULTIPLE_ROLES = 'discordVerifyMultipleRoles',
  DISCORD_USER_DISPLAY_NAME_CHANGE = 'discordUserDisplayNameChange',
  DISCORD_USER_PROFILE_PICTURE_CHANGE = 'discordUserProfilePictureChange',
  TWITTER_RETWEET = 'twitterRetweet',
  TWITTER_FOLLOW = 'twitterFollowUser',
  TWITTER_LIKE = 'twitterLikeTweet',
  TWITTER_REQUOTE = 'twitterRequoteTweet',
  TWITTER_TWEET_METRICS = 'twitterTweetMetrics',
  TWITTER_USER_PROFILE_PICTURE_CHANGE = 'twitterUserProfilePictureChange',
  TWITTER_USER_DISPLAY_NAME_CHANGE = 'twitterUserDisplayNameChange',
  SOCIAL_ACTIVITY = 'completeSocialActivity',
  BL_APP = 'Bullet_League_App',
  RR_APP = 'Racket_Rampage_App',
  STAKE_NFT = 'stakeNft',
  CONNECT_WALLET = 'connectWallet',
}

export const SeasonStateByEnum = {
  PRE_SEASON: 'COMING SOON',
  IN_SEASON: 'LIVE',
  BUFFER_TIME: 'SUBMITTING POINTS IN',
  SUBMITTING: 'SUBMITTING POINTS IN',
  CLAIM_WINDOW: 'Claim',
};

export const SeasonBadgeColor = {
  PRE_SEASON: 'yellow',
  IN_SEASON: 'green',
  BUFFER_TIME: 'yellow',
  SUBMITTING: 'yellow',
  CLAIM_WINDOW: 'yellow',
};
