import { Link } from 'gatsby';
import React, { FC, useEffect } from 'react';

import { biFields } from '@/bi/bi-constants';
import { commonBiEvents } from '@/bi/events/common-bi-events';
import { getCurrentTabForBi } from '@/common/utils';
// import Timer from '@/components/timer/timer';
import { useAuth } from '@/context/auth-context';
import { useSuperChampsContext } from '@/context/superchamps-context';

import { SeasonBadgeColor, SeasonStateByEnum } from '../constants';
import { SeasonStateEnum } from '../types';

export interface IHero {
  handleLearnMore: () => void;
}

const Hero: FC<IHero> = ({ handleLearnMore }) => {
  const { season, seasonState, isSeasonLoading } = useSuperChampsContext();
  const { user, setStep, setShowLogin, socialData, fetchSocialData } =
    useAuth();

  // const getTime = () => {
  //   if (!season) return 0;
  //   let time = 0;
  //   if (seasonState === SeasonStateEnum.PRE_SEASON) {
  //     time = season.startAt;
  //   }
  //   if (seasonState === SeasonStateEnum.IN_SEASON) {
  //     time = season.startAt + season.duration;
  //   }
  //   if (seasonState === SeasonStateEnum.BUFFER_TIME) {
  //     time = season.startAt + season.duration + season.rewardsCollectionBuffer;
  //   }
  //   return time;
  // };

  const isKycVerified = () => {
    if (socialData) {
      return socialData?.kycStatus === 'VERIFIED';
    }
    return false;
  };

  const noCta =
    seasonState !== SeasonStateEnum.PRE_SEASON &&
    seasonState !== SeasonStateEnum.IN_SEASON;

  useEffect(() => {
    if (user) {
      fetchSocialData();
    }
    // eslint-disable-next-line
  }, [user]);

  // console.log('socialData', socialData);
  // console.log('!isKycVerified', !isKycVerified());
  // console.log('seasonState', seasonState);
  return (
    <section className="hero-section">
      <div className="content">
        <h2>WELCOME TO THE $CHAMP TOKEN CHALLENGE</h2>
        <p>
          <strong>$CHAMP</strong> is the native token of the{' '}
          <strong>
            <i>Super Champs Universe</i>
          </strong>
          , a rich anime-inspired world that extends across video games, social
          media, digital & print comic books, must-own merch and more.
        </p>
        <p>
          <strong>$CHAMP</strong> tokens are used across the Super Champs
          ecosystem, including special NFTs, discounts on game currencies,
          special loot boxes, and other token-holder perks and privileges.
        </p>

        {user && season && seasonState ? (
          <div className="mt-5 pos-rel">
            <div
              className={`purple-box-container season-banner ${seasonState.toLowerCase()}`}
            >
              <div
                className={`status-badge banner-status ${SeasonBadgeColor[seasonState]}`}
              >
                {SeasonStateByEnum[seasonState]}
              </div>
              <div
                className={`banner-content flex jcc g-1 ${
                  noCta ? 'aic flex-col reverse' : ''
                }`}
              >
                <h2>{season.seasonChallengeName}</h2>
                <div className="banner-cta">
                  {/* <div className="mb-2">
                    <Timer
                      ids={{
                        days: 'timer-days',
                        hours: 'timer-hours',
                        minutes: 'timer-minutes',
                        seconds: 'timer-seconds',
                      }}
                      shortLabels={true}
                      time={getTime()}
                      className="claim-timer f-14"
                      onTimeUp={getSeasonState}
                    />
                  </div> */}
                  {seasonState === SeasonStateEnum.PRE_SEASON && (
                    <button
                      className="yellow-button small"
                      onClick={handleLearnMore}
                    >
                      HOW TO ENTER
                    </button>
                  )}
                  {seasonState === SeasonStateEnum.IN_SEASON && (
                    <Link to="/quests" className="yellow-button small">
                      ENTER NOW
                    </Link>
                  )}
                </div>
              </div>
            </div>
            {!isKycVerified() && seasonState === SeasonStateEnum.IN_SEASON && (
              <>
                <div className="info-flyer hide">
                  <div className="badge"></div>
                  <div className="info">
                    Super Badge is required to claim $CHAMPS,{' '}
                    <strong>
                      <Link to="/super-badge" className="yellow-link">
                        MINT NOW
                      </Link>
                    </strong>
                  </div>
                </div>
              </>
            )}
          </div>
        ) : (
          <>
            {user && isSeasonLoading && (
              <div className="banner-loader mt-5 pos-rel">
                <div
                  className="spinning-loader"
                  style={{ width: '55px', height: '55px' }}
                ></div>
              </div>
            )}
          </>
        )}

        {!user && (
          <div className="mt-5 home-login-cta-holder">
            <button
              className="yellow-button large home-login-cta"
              onClick={() => {
                setStep('login');
                setShowLogin(true);
                commonBiEvents.click({
                  event_name: biFields.eventNames.WEB_ONBOARDING,
                  str_field1: 'login',
                  str_field2: getCurrentTabForBi(),
                });
              }}
            >
              LOGIN
            </button>
          </div>
        )}
      </div>
      <div className="hero"></div>
    </section>
  );
};

export default Hero;
