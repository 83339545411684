// import { Link } from 'gatsby';
import React, { useEffect } from 'react';

import { commonBiEvents } from '@/bi/events/common-bi-events';
import utils, {
  getCurrentTabForBi,
  getSeasonStartTime,
  isBrowser,
} from '@/common/utils';
import SuperChampsLayout from '@/components/layout/superchamps-layout';

import Apps from './apps';
import Exclusives from './exclusives';
import Hero from './hero';
import HowTo from './how-to';

const Seasons = () => {
  // const { user, setShowLogin, socialData } = useAuth();

  const handleLearnMore = () => {
    if (isBrowser()) {
      const l = utils.el('how-it-works');
      if (l) {
        l.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'nearest',
        });
      }
    }
  };

  useEffect(() => {
    commonBiEvents.view({
      str_field1: getCurrentTabForBi(),
      int_field0: getSeasonStartTime(),
    });
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <SuperChampsLayout className="home-page">
        <div className="container">
          <Hero handleLearnMore={handleLearnMore} />
          <div className="learn-more" onClick={handleLearnMore}>
            Learn More
            <div className="double-arrow-down"></div>
          </div>
          <HowTo />
          <Exclusives />
        </div>
        <Apps />
      </SuperChampsLayout>
    </>
  );
};

export default Seasons;
