import React, { FC } from 'react';

const HowTo: FC = () => {
  return (
    <section className="how-it-works" id="how-it-works">
      <h3>HOW TO PLAY & EARN</h3>
      <div className="black-steps-container no-bg how-to">
        <div className="step">
          <div className="content">
            <h3>
              COMPLETE
              <br />
              QUESTS
            </h3>
            <p>
              Choose from Quests that take you into
              <br />
              Super Champs mobile games, web3
              <br />
              activities and social experiences.
            </p>
          </div>
          <div className="image">
            <div className="complete-quest"></div>
          </div>
        </div>
        <div className="yellow-arrow">&nbsp;</div>
        <div className="step">
          <div className="content">
            <h3>
              EARN QUEST
              <br />
              POINTS
            </h3>
            <p>
              Earn Quest Points (QP) by
              <br />
              completing Quests.
            </p>
          </div>
          <div className="image">
            <div className="earn-points"></div>
          </div>
        </div>
        <div className="yellow-arrow">&nbsp;</div>
        <div className="step">
          <div className="content">
            <h3>
              COMPETE
              <br />
              FOR TOKENS
            </h3>
            <p className="lengthy-text">
              At the end of the Season, use your quest points to compete for
              $CHAMP.
            </p>

            {/* <button className="yellow-button small mt-3">ENTER NOW</button> */}
          </div>
          <div className="image">
            <div className="win-tokens"></div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HowTo;
