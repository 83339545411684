import React, { FC } from 'react';

import SeasonText from '../season-text';

const Exclusives: FC = () => {
  return (
    <section className="exclusives-section">
      <div className="hero-glow">
        <div className="hero"></div>
      </div>
      <div className="content">
        <SeasonText />
      </div>
    </section>
  );
};

export default Exclusives;
