import React, { FC } from 'react';

import OpenSeaIcon from '@/assets/images/icons/icon-opensea-white.png';
import { commonBiEvents } from '@/bi/events/common-bi-events';
import { goToLink } from '@/common/utils';

const SeasonText: FC = () => {
  return (
    <div className="feature-list">
      <h3>SEASON 1 IS LIVE!</h3>
      <div className="list">
        <div className="item">
          <div className="number">1</div>
          <div className="text">
            Participate in <strong>Season 1 quests</strong>, open to all
            players, and enjoy a variety of exciting challenges. Earn quest
            points and compete for <strong>$CHAMP</strong>.
          </div>
        </div>
        <div className="item">
          <div className="number">2</div>
          <div className="text">
            Get <strong>Genesis NFTs</strong> to unlock access to the Genesis
            Pool, reserved exclusively for <strong>Genesis NFT holders</strong>.
          </div>
        </div>
        <div className="item">
          <div className="text">
            <strong>Note</strong> - <strong>$CHAMP</strong> rewards aren't
            currently available in all regions. If your region isn't supported,
            we'll announce as soon as your geo is confirmed.
          </div>
        </div>
      </div>
      <div className="flex jcc g-1 mt-4">
        <button
          className="blue-button-v2"
          onClick={() => {
            commonBiEvents.ctaClick('OPENSEA', 'navigation');
            goToLink('https://opensea.io/collection/tennis-champs-genesis');
          }}
        >
          <img
            src={OpenSeaIcon}
            width={30}
            alt="Open Sea"
            style={{ marginBottom: '5px' }}
          />
          OPENSEA
        </button>

        <button
          className="magic-eden-button"
          onClick={() => {
            commonBiEvents.ctaClick('MAGIC EDEN', 'navigation');
            goToLink(
              'https://magiceden.io/collections/ethereum/0xc71a0306a0a628c616dac42097bdc21ecdbc5d9d',
            );
          }}
        >
          {/* MAGIC EDEN */}
        </button>

        <button
          className="blur-button-white"
          onClick={() => {
            commonBiEvents.ctaClick('BLUR', 'navigation');
            goToLink('https://blur.io/eth/collection/tennis-champs-genesis');
          }}
        >
          {/* BLUR */}
        </button>
      </div>
    </div>
  );
};

export default SeasonText;
