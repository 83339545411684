import React from 'react';

import MetaTags from '@/components/meta-tags';
import Seasons from '@/features/quests/seasons';

const SCSeasons = () => {
  return <Seasons />;
};

export default SCSeasons;

export function Head() {
  return (
    <>
      <title>Super Champs HQ - Welcome to the $CHAMP token challenge</title>

      {/** Work Sans Google font  */}
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="" />
      <link
        href="https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;500;600;700;800;900&family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap"
        rel="stylesheet"
      />
      <MetaTags />
    </>
  );
}
