import React, { FC } from 'react';

import bLImage from '@/assets/images/app-bullet-league.png';
import rRIamge from '@/assets/images/app-racket-rampge.png';

const Apps: FC = () => {
  return (
    <div className="apps-section-container">
      <section className="apps-section">
        <div className="heroes">
          <div className="app">
            <img
              src={rRIamge}
              alt="Racket Rampage"
              width={530}
              style={{ marginTop: '25px' }}
            />
          </div>
          <div className="app">
            <img src={bLImage} alt="Bullet League" width={550} />
          </div>
        </div>

        <div className="mt-5 flex jcc">
          <a
            target="_blank"
            rel="noreferrer"
            className="yellow-button"
            href="https://www.superchamps.com/?utm_source=superchamps_website"
          >
            LEARN MORE
          </a>
        </div>
      </section>
    </div>
  );
};

export default Apps;
